import { Attachment } from 'interface'
import { apiClient } from './config'

export const createLicense = async (params: {
  number: string | null
  state_abbr: string
  county_id: number | null
  municipality_id: number | null
  notes: string | null
  start_date: string | null
  submission_date: string | null
  issued_date: string
  expiration_date: string
  sales_reps_ids: number[]
}) : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    message: string
    license_id: number
  }
}> => {
  const response = await apiClient('licenses', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  })

  return response
}

export const uploadLicenseAttachment = async (licenseId: number, file: File) : Promise<{
  _metadata: {
    success: boolean
  },
  results: {
    message: string
    attachment_id: number
  }
}> => {
  const formData = new FormData()
  formData.append('attachment', file)
  
  const response = await apiClient(`licenses/${licenseId}/attachments`, {
    method: 'POST',
    body: formData
  })

  return response
}

export const fetchLicenseAttachments = async (licenseId: number): Promise<{
  _metadata: {
    success: boolean
  }
  result: {
    attachments: Attachment[]
  }
}> => {
  const response = await apiClient(`licenses/${licenseId}/attachments`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const deleteLicenseAttachment = async (licenseId: number, attachmentId: number) : Promise<{
  _metadata: {
    success: boolean
  }
  result: {
    message: string
  }
}> => {
  const response = await apiClient(`licenses/${licenseId}/attachments/${attachmentId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const previewLicenseAttachment = async (licenseId: number, attachmentId: number) : Promise<any> => {
  const response = await apiClient(`licenses/${licenseId}/attachments/${attachmentId}/preview`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseMode: 'blob'
  })

  return response
}

export const downloadLicenseAttachment = async (licenseId: number, attachmentId: number) : Promise<any> => {
  const response = await apiClient(`licenses/${licenseId}/attachments/${attachmentId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseMode: 'blob'
  })

  return response
}

export const fetchLicense = async (licenseId: number) : Promise<any> => {
  const response = await apiClient(`licenses/${licenseId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}

export const updateLicense = async (params: {
  number: string | null
  state_abbr: string
  county_id: number | null
  municipality_id: number | null
  notes: string | null
  start_date: string | null
  submission_date: string | null
  issued_date: string
  expiration_date: string
}, licenseId: number) : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    message: string
    license_id: number
  }
}> => {
  const response = await apiClient(`licenses/${licenseId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params)
  })

  return response
}

