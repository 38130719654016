import { AptiveIcon, Avatar, Badge, Brand, Button, Header } from '@aptive-env/storybook'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const MainHeader: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Header className="pt-[15px] px-[32px] pb-[16px] leading-none flex justify-between border-b-[1px]">
      <Brand
        className='cursor-pointer'
        imgSrc="/assets/image/logos/aptive-wordmarks.svg"
        label="aptive-wordmarks"
        onClick={() => navigate('/')}
      />
      <div className='flex flex-row items-center'>
        <div className="relative">
          <Button
            aria-haspopup="true"
            aria-label="Dropdown Summer 2022"
            icon="chevronDown"
            isFilled
            onClick={() => { }}
            position="right"
            variant="neutral"
          >
            Summer 2022
          </Button>
        </div>
        <div className='my-auto ml-[16px] mr-[4px]'>
          <AptiveIcon
            className="w-[24px] h-[24px] stroke-[#9CA3AF] fill-none"
            icon="bell"
          />
        </div>
        <div className="relative">
          <Button
            aria-haspopup="true"
            aria-label="User Profile Dropdown"
            icon="chevronDown"
            isFilled
            onClick={() => { }}
            position="right"
            variant="neutral"
            className='py-[7px] border-0'
          >
            <Avatar
              imgSrc="/assets/image/avatar/user.png"
              label="User"
              size="default"
              aria-haspopup="true"
              aria-label="User Avatar"
            />
            <Badge className="ml-[11px] mr-[5px]" color="success">
              Admin
            </Badge>
          </Button>
        </div>
      </div>
    </Header>
  )
}

export default MainHeader
