import React, { PropsWithChildren } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import MainHeader from 'components/Header'
import { Navbar, Navbars, Toast } from '@aptive-env/storybook'
import { useToast } from 'hooks/useToast'

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC<PropsWithChildren<MainLayoutProps>> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location

  const { toast, setToast } = useToast()

  const getDefaultNav = () => {
    switch (pathname) {
    case '/company-info':
      return 1
    case '/license-applications':
      return 2
    case '/requirements':
      return 3
    case '/sales-representatives':
      return 4
    default:
      return 0
    }
  }

  return (
    <>
      <div className="flex flex-col h-full" data-testid="MainLayout">
        <MainHeader />
        <div className="relative flex flex-col grow">
          <Navbars className='px-[32px]' defaultValue={getDefaultNav()}>
            <Navbar title='Dashboard' onClick={() => navigate('/')}></Navbar>
            <Navbar title='Company Info' onClick={() => navigate('/company-info')}></Navbar>
            <Navbar title='License applications' onClick={() => navigate('/license-applications')}></Navbar>
            <Navbar title='Requirements' onClick={() => navigate('/requirements')}></Navbar>
            <Navbar title='Sales representatives' onClick={() => navigate('/sales-representatives')}></Navbar>
          </Navbars>
          <div className="flex flex-col flex-1 bg-gray-50 overflow-auto">
            {children}
          </div>
        </div>
      </div>
      {toast && (
        <div className="fixed top-3 right-3 flex flex-col gap-2 z-[100]">
          <Toast
            dismissible
            isIcon
            onDismiss={() => setToast(null)}
            status={toast.type}
          >
            {toast.message}
          </Toast>
        </div>
      )}
    </>
  )
}

export default MainLayout
