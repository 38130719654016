import { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { CustomFormElement } from 'components'
import { licenseModalConstants } from 'lib/constants'
import LicenseHeader from './LicenseHeader'
import LicenseFooter from './LicenseFooter'

import { Attachment, County, License, Municipality, SalesRepresentative, State } from 'interface'
import { 
  fetchSalesRepresentativesList, 
  fetchCounties,
  fetchMunicipalities,
  createLicense,
  fetchSalesRepresentativeLicenses, 
  uploadLicenseAttachment, 
  updateLicense,
  deleteLicenseAttachment,
  previewLicenseAttachment,
  downloadLicenseAttachment
} from 'services'

import * as yup from 'yup'
import { formatDate } from 'lib/utils'
import moment from 'moment'
import { capitalize } from 'lodash'

const {
  NEW_LICENSE,
  EDIT_LICENSE,
  REP_LABEL,
  STATE_LABEL,
  COUNTY_LABEL,
  MUNICIPALITY_LABEL,
  LICENSE_NUMBER_LABEL,
  LICENSE_NUMBER_PLACEHOLDER,
  START_DATE_LABEL,
  SUBMISSION_DATE_LABEL,
  EXPIRATION_DATE_LABEL,
  ISSUED_DATE_LABEL,
  NOTES_LABEL,
  NOTES_PLACEHOLDER,
  ATTACHMENTS_LABEL,
  ATTACHMENTS_DESCRIPTION,
} = licenseModalConstants

type LicenseFormValues = {
  number?: string
  state_abbr: string
  county_id?: number
  municipality_id?: number
  notes?: string
  start_date?: string
  submission_date?: string
  issued_date: string
  expiration_date: string
  sales_reps_ids: number[]
  attachments?: (File | Attachment)[]
}

const newLicenseSchema = yup.object().shape({
  state_abbr: yup.string().required('Field must be selected.'),
  issued_date: yup.string().required('Field must be selected.'),
  expiration_date: yup.string().required('Field must be selected.'),
  sales_reps_ids: yup
    .array()
    .of(yup.number().required('Each sales rep ID must be a number.'))
    .required('Field must be selected.')
    .min(1, 'Field must be selected.'),
})

const editLicenseSchema = yup.object().shape({
  state_abbr: yup.string().required('Field must be selected.'),
  issued_date: yup.string().required('Field must be selected.'),
  expiration_date: yup.string().required('Field must be selected.'),
})

interface LicenseFormProps {
  license: License | null;
  salesRepresentative: SalesRepresentative | null;
  setSalesRepresentativeLicenses: React.Dispatch<React.SetStateAction<License[]>>;
  onClose: () => void;
  states:  State[];
  selectedLicenseAttachments: Attachment[];
}

const LicenseForm: FC<LicenseFormProps> = ({
  license,
  onClose,
  states,
  salesRepresentative,
  setSalesRepresentativeLicenses,
  selectedLicenseAttachments
}) => {
  const [salesRepresentatives, setSalesRepresentatives] = useState<SalesRepresentative[]>([])
  const [counties, setCounties] = useState<County[]>([])
  const [municipalities, setMunicipalities] = useState<Municipality[]>([])

  const fetchCountiesByState = async (stateAbbr: string) => {
    const fetchCountiesResponse = await fetchCounties({ state_abbr: stateAbbr })
    setCounties(fetchCountiesResponse.result.counties)
  }

  const fetchMunicipalitiesByState = async (stateAbbr: string) => {
    const fetchMunicipalitiesResponse = await fetchMunicipalities({ state_abbr: stateAbbr })
    setMunicipalities(fetchMunicipalitiesResponse.result.municipalities)
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  let defaultValues: any = {}

  if (license) {
    defaultValues = {
      number: license.number,
      state_abbr: license.state.abbreviation,
      county_id: license.county?.id,
      municipality_id: license.municipality?.id,
      notes: license.notes,
      start_date: license.start_date,
      submission_date: license.submission_date,
      issued_date: license.issued_date,
      expiration_date: license.expiration_date,
    }
  }
  if (salesRepresentative) {
    defaultValues['sales_reps_ids'] = [salesRepresentative.id]
  }

  const methods = useForm<LicenseFormValues>({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(license ? editLicenseSchema : newLicenseSchema) as any
  })

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isValid, isDirty, errors, isSubmitting },
    register,
    resetField,
    watch,
  } = methods

  const handleChange = useCallback((event: { target: { name: string; value: any } }) => {
    const { name, value } = event.target
    setValue(name as keyof LicenseFormValues, value, { shouldValidate: true, shouldDirty: true })

    if (name === 'state_abbr') {
      fetchCountiesByState(value)
      fetchMunicipalitiesByState(value)
      resetField('county_id')
      resetField('municipality_id')
    }
  }, [setValue, resetField])

  const handlePreviewFile = async (file: File | Attachment) => {
    if ((file as Attachment).id && license) {
      const response = await previewLicenseAttachment(license.id, (file as Attachment).id)
      const url = URL.createObjectURL(response)
      window.open(url, '_blank')
    } else {
      window.open(URL.createObjectURL(file as File), '_blank')
    }
  }

  const handleDownloadFile = async (file: File | Attachment) => {
    if ((file as Attachment).id) {
      const attachment = file as Attachment
      const response = await downloadLicenseAttachment(license?.id as number, attachment.id)
      const url = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.download = attachment.filename
      link.click()
    } else {
      const asFile = file as File
      const url = URL.createObjectURL(asFile)
      const link = document.createElement('a')
      link.href = url
      link.download = asFile.name
      link.click()
      URL.revokeObjectURL(url)
    }
  }

  const handleSubmitLicenseFrom = useCallback(async () => {
    const values = getValues()
    if (!license) {
      const createLicenseResponse = await createLicense({
        sales_reps_ids: salesRepresentative ? [salesRepresentative.id] : values.sales_reps_ids,
        number: values.number || null,
        state_abbr: values.state_abbr,
        county_id: values.county_id || null,
        municipality_id: values.municipality_id || null,
        expiration_date: moment(values.expiration_date).format(formatDate.FORMAT_REQUEST),
        issued_date: moment(values.issued_date).format(formatDate.FORMAT_REQUEST),
        notes: values.notes || null,
        start_date: values.start_date ? moment(values.start_date).format(formatDate.FORMAT_REQUEST) : null,
        submission_date: values.submission_date ? moment(values.submission_date).format(formatDate.FORMAT_REQUEST) : null,
      })
      try {
        if (createLicenseResponse.result.license_id) {
          if (values.attachments && values.attachments.length > 0) {
            for (const attachment of values.attachments) {
              uploadLicenseAttachment(createLicenseResponse.result.license_id, attachment as File)
            }
          }

          if (salesRepresentative) {
            const licensesResponse = await fetchSalesRepresentativeLicenses(salesRepresentative.id)
            setSalesRepresentativeLicenses(licensesResponse.result.licenses)
          }
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      const updateLicenseResponse = await updateLicense({
        number: values.number || null,
        state_abbr: values.state_abbr,
        county_id: values.county_id || null,
        municipality_id: values.municipality_id || null,
        expiration_date: moment(values.expiration_date).format(formatDate.FORMAT_REQUEST),
        issued_date: moment(values.issued_date).format(formatDate.FORMAT_REQUEST),
        notes: values.notes || null,
        start_date: values.start_date ? moment(values.start_date).format(formatDate.FORMAT_REQUEST) : null,
        submission_date: values.submission_date ? moment(values.submission_date).format(formatDate.FORMAT_REQUEST) : null,
      }, license.id)
      try {
        if (updateLicenseResponse.result.license_id && salesRepresentative) {
          
          if (values.attachments && values.attachments.length >= 0) {
            const attachmentIds = selectedLicenseAttachments.map((attachment) => attachment.id)
            const currentAttachmentIds = values.attachments.map((attachment) => (attachment as Attachment).id).filter((id) => id)
            const deletedAttachmentsIds = attachmentIds.filter((attachmentId) => !currentAttachmentIds?.includes(attachmentId))

            deletedAttachmentsIds.forEach(async (attachmentId) => {
              await deleteLicenseAttachment(updateLicenseResponse.result.license_id, attachmentId)
            })

            for (const attachment of values.attachments) {
              if (!(attachment as Attachment).id) {
                await uploadLicenseAttachment(updateLicenseResponse.result.license_id, attachment as File)
              }
            }
          }

          const licensesResponse = await fetchSalesRepresentativeLicenses(salesRepresentative?.id)
          setSalesRepresentativeLicenses(licensesResponse.result.licenses)
        }
      } catch (error) {
        console.log(error)
      }
    }
    
    onClose()
  }, [
    getValues, 
    license, 
    onClose, 
    salesRepresentative, 
    selectedLicenseAttachments, 
    setSalesRepresentativeLicenses
  ])

  useEffect(() => {
    if (license) {
      fetchCountiesByState(license.state.abbreviation)
      fetchMunicipalitiesByState(license.state.abbreviation)
    } else if (!salesRepresentative)
      loadSalesRepresentatives()
  }, [license, salesRepresentative])

  const loadSalesRepresentatives = async () => {
    const fetchSalesRepresentativesResponse = await fetchSalesRepresentativesList({})
    setSalesRepresentatives(fetchSalesRepresentativesResponse.result.reps)
  }

  return (
    <div className="w-[576px]">
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(handleSubmitLicenseFrom)}>
          <LicenseHeader label={license ? EDIT_LICENSE : NEW_LICENSE} onClose={onClose} />     
          <div className="px-6 grid grid-cols-1 py-6 gap-y-6 gap-x-4 sm:grid-cols-6 h-[473px] overflow-y-auto no-scrollbar">
            {!license && !salesRepresentative && (
              <CustomFormElement
                id={'sales_reps_ids'}
                name={'sales_reps_ids'}
                label={REP_LABEL}
                type="multiSelect"
                isMulti={true}
                options={salesRepresentatives.map((salesRepresentative) => ({
                  value: salesRepresentative.id,
                  label: salesRepresentative.name,
                }))}
                onChange={handleChange}
                value={getValues('sales_reps_ids')}
                register={register}
                error={errors?.sales_reps_ids as any}
                formElementWrapperClassName='sm:col-span-6'
                placeholder='Select employee'
                required
              />
            )}
            <CustomFormElement
              id={'state_abbr'}
              name={'state_abbr'}
              label={STATE_LABEL}
              type="select"
              selectOptions={[
                ...states.map((state) => ({ 
                  value: state.abbreviation, 
                  name: state.name
                }))
              ]}
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.state_abbr as any}
              formElementWrapperClassName='sm:col-span-2'
              searchable
              required
            />
            <CustomFormElement
              id={'county_id'}
              name={'county_id'}
              label={COUNTY_LABEL}
              type="select"
              selectOptions={[
                ...counties.map((county) => ({ 
                  value: county.id, 
                  name: capitalize(county.name)
                }))
              ]}
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.county_id as any}
              formElementWrapperClassName='sm:col-span-2'
              searchable
              disabled={counties.length === 0}
            />
            <CustomFormElement
              id={'municipality_id'}
              name={'municipality_id'}
              label={MUNICIPALITY_LABEL}
              type="select"
              selectOptions={[
                ...municipalities.map((municipality) => ({ 
                  value: municipality.id, 
                  name: capitalize(municipality.name)
                }))
              ]}
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.municipality_id as any}
              formElementWrapperClassName='sm:col-span-2'
              dropdownPosition='left'
              searchable
              disabled={municipalities.length === 0}
            />
            <CustomFormElement
              id={'number'}
              name={'number'}
              label={LICENSE_NUMBER_LABEL}
              placeholder={LICENSE_NUMBER_PLACEHOLDER}
              type="text"
              onChange={handleChange}
              register={register}
              error={errors?.number as any}
              formElementWrapperClassName='sm:col-span-6'
            />
            <CustomFormElement
              id={'start_date'}
              name={'start_date'}
              label={START_DATE_LABEL}
              type="date"
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.start_date as any}
              formElementWrapperClassName='sm:col-span-3'
            />
            <CustomFormElement
              id={'submission_date'}
              name={'submission_date'}
              label={SUBMISSION_DATE_LABEL}
              type="date"
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.submission_date as any}
              formElementWrapperClassName='sm:col-span-3'
              dropdownPosition='left'
            />
            <CustomFormElement
              id={'issued_date'}
              name={'issued_date'}
              label={ISSUED_DATE_LABEL}
              type="date"
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.issued_date as any}
              formElementWrapperClassName='sm:col-span-3'
              required
            />
            <CustomFormElement
              id={'expiration_date'}
              name={'expiration_date'}
              label={EXPIRATION_DATE_LABEL}
              type="date"
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.expiration_date as any}
              formElementWrapperClassName='sm:col-span-3'
              required
              dropdownPosition='left'
            />
            <CustomFormElement
              id={'notes'}
              name={'notes'}
              label={NOTES_LABEL}
              placeholder={NOTES_PLACEHOLDER}
              rows={1}
              type="textArea"
              onChange={handleChange}
              register={register}
              error={errors?.notes as any}
              formElementWrapperClassName='sm:col-span-6'
            />
            <CustomFormElement
              id={'attachments'}
              name={'attachments'}
              type="file"
              attachmentLabel={ATTACHMENTS_LABEL}
              attachmentDescription={ATTACHMENTS_DESCRIPTION}
              acceptedFileTypes={['application/pdf', 'image/jpeg', 'image/png']}
              maxFileSize={30 * 1024 * 1024}
              maxFileCount={5}
              defaultFiles={getValues('attachments')}
              onChange={handleChange}
              register={register}
              {...(license && { selectedLicenseAttachments })}
              onPreviewFile={handlePreviewFile}
              onDownloadFile={handleDownloadFile}
              formElementWrapperClassName='sm:col-span-6'
            />
          </div>
          <LicenseFooter
            onCancel={onClose}
            onSave={() => {}}
            isValid={isValid}
            isDirty={isDirty}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default LicenseForm
