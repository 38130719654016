import { useState, useEffect } from 'react'
import { Navbar, Navbars, AptiveIcon } from '@aptive-env/storybook'

import { License, SalesRepresentative, State } from 'interface'
import DetailHeader from './DetailHeader'
import DetailPersonalInfo from './DetailPersonalInfo'
import DetailLicensing from './DetailLicensing'
import DetailNotes from './DetailNotes'
import DeleteLicenseModal from '../DeleteLicense'
import {
  fetchSalesRepresentativeProfile,
  fetchSalesRepresentativeLicenses
} from 'services'

interface IDetailDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  salesRepresentative: SalesRepresentative | null;
  salesRepresentativeLicenses: License[];
  setSalesRepresentativeLicenses: React.Dispatch<React.SetStateAction<License[]>>;
  states: State[];
  onLicenseAdd: () => void;
  onLicenseEdit: (license: License) => void;
}

const ViewDetailDrawer: React.FC<IDetailDrawerProps> = ({
  isOpen,
  onClose,
  salesRepresentative,
  salesRepresentativeLicenses,
  setSalesRepresentativeLicenses,
  states,
  onLicenseAdd,
  onLicenseEdit
}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [profile, setProfile] = useState<SalesRepresentative | null>(null)
  const [isDeleteLicenseModalOpen, setIsDeleteLicenseModalOpen] = useState(false)
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null)
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!isOpen)
      setActiveTab(0)

    if (isOpen && salesRepresentative)
      loadSalesRepresentativeProfile(salesRepresentative.id)
    // eslint-disable-next-line
  }, [isOpen, salesRepresentative])

  useEffect(() => {
    if (!isOpen)
      setSearch('')
    
    if (isOpen && salesRepresentative)
      loadSalesRepresentativeLicenses(salesRepresentative.id, search)
    // eslint-disable-next-line
  }, [isOpen, salesRepresentative, search])

  const loadSalesRepresentativeProfile = async (id: number) => {
    const profileResponse = await fetchSalesRepresentativeProfile(id)

    setProfile({...profileResponse.result.rep, team: salesRepresentative ? salesRepresentative.team : null })
  }

  const loadSalesRepresentativeLicenses = async (id: number, search: string = '') => {
    const licensesResponse = await fetchSalesRepresentativeLicenses(id, search)

    setSalesRepresentativeLicenses(licensesResponse.result.licenses)
  }

  const onLicenseDelete = (license: License) => {
    setSelectedLicense(license)
    setIsDeleteLicenseModalOpen(true)
  }

  const handleHideShowLicense = (licenseId: number, isHidden: boolean) => {
    const tempLicenses = [...salesRepresentativeLicenses]
    for (const license of tempLicenses) {
      if (license.license_id === licenseId) {
        license.hidden = isHidden
      }
    }
    setSalesRepresentativeLicenses(tempLicenses)
  }

  return (
    <>
      {isOpen && (
        <div className="sidebar fixed top-0 left-0 h-full w-full z-[40]">
          <div className="bg-[#00000040] absolute top-0 left-0 h-full w-full" onClick={onClose}></div>
          <div className="flex flex-col absolute right-0 bg-white h-full w-[666px] overflow-x-hidden transition-all duration-500">
            <div className="flex flex-col relative px-6 pt-6 pb-4 bg-gray-100 border-b border-gray-200">
              <DetailHeader
                profile={profile}
                viewFull
              />
              <AptiveIcon
                className="absolute top-4 right-5 w-[24px] h-[24px] stroke-[#9CA3AF] fill-none cursor-pointer"
                icon="x"
                onClick={onClose}
              />
            </div>
            <Navbars className='gap-4 px-6' defaultValue={0}>
              <Navbar className='px-0 py-[26px]' title='Personal Info' onClick={() => setActiveTab(0)} />
              <Navbar className='px-0 py-[26px]' title='Licensing' onClick={() => setActiveTab(1)} />
              <Navbar className='px-0 py-[26px]' title='Notes' onClick={() => setActiveTab(2)} />
            </Navbars>
            <div className="flex flex-1 overflow-hidden">
              {activeTab === 0 && <DetailPersonalInfo />}
              {activeTab === 1 && 
                <DetailLicensing
                  licenses={salesRepresentativeLicenses}
                  states={states}
                  search={search}
                  onSearch={setSearch}
                  onAdd={onLicenseAdd}
                  onEdit={onLicenseEdit}
                  onDelete={onLicenseDelete}
                />
              }
              {activeTab === 2 && <DetailNotes />}
            </div>
          </div>
        </div>
      )}
      <DeleteLicenseModal
        isOpen={isDeleteLicenseModalOpen}
        onClose={() => setIsDeleteLicenseModalOpen(false)}
        onHideShow={handleHideShowLicense}
        license={selectedLicense}
        salesRepresentative={salesRepresentative}
      />
    </>
  )
}

export default ViewDetailDrawer
