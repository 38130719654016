import { useCallback, useState, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomFormElement } from 'components'
import { prioritySelectionOption, submissionTypeSelectionOption,} from 'lib/configs'
import { requirementModalConstants } from 'lib/constants'
import ChecklistFormHeader from './ChecklistFormHeader'
import ChecklistFormFooter from './ChecklistFormFooter'
import { yupResolver } from '@hookform/resolvers/yup'
import { AptiveIcon, Checkbox } from '@aptive-env/storybook'
import { capitalize } from 'lodash'
import * as yup from 'yup'

import {
  ChecklistRequirements,
  ChecklistRequirementsNames,
  State,
  County,
  Municipality,
  ChecklistSubmissionType,
  ChecklistPriority,
  Checklist,
  Attachment
} from 'interface'
import {
  fetchCounties,
  fetchMunicipalities,
  createChecklist,
  updateChecklist,
  uploadChecklistAttachment,
  previewChecklistAttachment,
  downloadChecklistAttachment,
  deleteChecklistAttachment
} from 'services'

type ChecklistFormValues = {
  state_abbr?: string
  county_id?: number
  municipality_id?: number
  city_contact_name?: string
  city_contact_email?: string
  city_contact_phone?: string
  priority?: ChecklistPriority
  submission_type?: ChecklistSubmissionType
  submission_email?: string | null
  submission_url?: string | null
  notes?: string
  attachments?: (File | Attachment)[]
}

interface ChecklistFormProps {
  checklist: Checklist | null;
  checklistAttachments: Attachment[]
  states: State[];
  onCancel: () => void;
  onSubmit: ({ success, message }: { success: boolean, message: string }) => void;
}

const submissionUrlSchema = yup
  .string()
  .nullable()
  .test('is-url-valid', 'Invalid URL', value => !value || /^([\da-z.-]+)\.([a-z.]{2,6})(\/[\w .-]*)*\/?$/.test(value))
  .when('submission_type', {
    is: 'online',
    then: schema => schema.required('URL is required'),
    otherwise: schema => schema.nullable(),
  })

const submissionEmailSchema = yup
  .string()
  .nullable()
  .email('Invalid email address')
  .when('submission_type', {
    is: 'email',
    then: schema => schema.required('Email is required'),
    otherwise: schema => schema.nullable(),
  })

const checklistSchema = yup.object().shape({
  state_abbr: yup.string().required('Field must be selected.'),
  priority: yup.mixed<ChecklistPriority>().required('Field must be selected.'),
  submission_type: yup.mixed<ChecklistSubmissionType>().required('Field must be selected.'),
  submission_email: submissionEmailSchema,
  submission_url: submissionUrlSchema,
  county_id: yup.number().optional(),
  municipality_id: yup.number().optional(),
  city_contact_name: yup.string().optional(),
  city_contact_email: yup.string().email('Invalid email'),
  city_contact_phone: yup.string().optional(),
  notes: yup.string().optional(),
  attachments: yup.array().of(yup.mixed<File>().required()).optional(),
})

const {
  REQUIREMENTS,
  NEW_REQUIREMENTS,
  STATE_LABEL,
  COUNTY_LABEL,
  MUNICIPALITY_LABEL,
  CITY_CONTACT_NAME_LABEL,
  CITY_CONTACT_NAME_PLACEHOLDER,
  CITY_CONTACT_EMAIL_LABEL,
  CITY_CONTACT_EMAIL_PLACEHOLDER,
  CITY_CONTACT_PHONE_LABEL,
  CITY_CONTACT_PHONE_PLACEHOLDER,
  ATTACHMENTS_LABEL,
  PRIORITY_LABEL,
  SUBMISSION_TYPE_LABEL,
  NOTES_TYPE_LABEL,
  NOTES_TYPE_PLACEHOLDER,
  SUBMISSION_URL_LABEL,
  SUBMISSION_URL_PLACEHOLDER,
  SUBMISSION_URL_MARK,
  SUBMISSION_EMAIL_ADDRESS_LABEL,
  SUBMISSION_EMAIL_ADDRESS_PLACEHOLDER,
} = requirementModalConstants

const ChecklistForm: React.FC<ChecklistFormProps> = ({
  checklist,
  checklistAttachments,
  states,
  onCancel,
  onSubmit,
}) => {
  const [counties, setCounties] = useState<County[]>([])
  const [municipalities, setMunicipalities] = useState<Municipality[]>([])

  const [requirements, setRequirements] = useState<ChecklistRequirements>({
    registration_in_person: checklist?.requirements?.registration_in_person ?? false,
    business_license: checklist?.requirements?.business_license ?? false,
    wet_signature: checklist?.requirements?.wet_signature ?? false,
    certificate_of_authority: checklist?.requirements?.certificate_of_authority ?? false,
    digital_signature: checklist?.requirements?.digital_signature ?? false,
    letter_of_authorization: checklist?.requirements?.letter_of_authorization ?? false,
    background_check: checklist?.requirements?.background_check ?? false,
    personal_references: checklist?.requirements?.personal_references ?? false,
    fingerprints: checklist?.requirements?.fingerprints ?? false,
    local_references: checklist?.requirements?.local_references ?? false,
    identification_requirements: checklist?.requirements?.identification_requirements ?? false,
    sales_tax_id:checklist?.requirements?.sales_tax_id ?? false,
    photo_requirements: checklist?.requirements?.photo_requirements ?? false,
    vehicle_registration: checklist?.requirements?.vehicle_registration ?? false,
    bond: checklist?.requirements?.bond ?? false,
    vehicle_insurance: checklist?.requirements?.vehicle_insurance ?? false,
    certificate_of_insurance: checklist?.requirements?.certificate_of_insurance ?? false,
    additional_appointments_required: checklist?.requirements?.additional_appointments_required ?? false,
    articles_of_incorporation: checklist?.requirements?.articles_of_incorporation ?? false,
    professional_references: checklist?.requirements?.professional_references ?? false,
  })
  const [isViewHistoryOpen, setIsViewHistoryOpen] = useState(true)

  const fetchCountiesByState = async (stateAbbr: string) => {
    const fetchCountiesResponse = await fetchCounties({ state_abbr: stateAbbr })
    setCounties(fetchCountiesResponse.result.counties)
  }

  const fetchMunicipalitiesByState = async (stateAbbr: string) => {
    const fetchMunicipalitiesResponse = await fetchMunicipalities({ state_abbr: stateAbbr })
    setMunicipalities(fetchMunicipalitiesResponse.result.municipalities)
  }

  const defaultValues: ChecklistFormValues = checklist ? {
    state_abbr: checklist.state?.abbreviation,
    county_id: checklist.county?.id ?? 0,
    municipality_id: checklist.municipality?.id ?? 0,
    city_contact_name: checklist.city_contact_name ?? undefined,
    city_contact_email: checklist.city_contact_email ?? undefined,
    city_contact_phone: checklist.city_contact_phone ?? undefined,
    notes: checklist.notes ?? undefined,
    priority: checklist.priority,
    submission_type: checklist.submission_type,
    submission_url: checklist.submission_url ? checklist.submission_url.replace('https://', '') : undefined,
    submission_email: checklist.submission_email,
    attachments: checklistAttachments
  } : {}

  const methods = useForm<ChecklistFormValues>({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver<ChecklistFormValues>(checklistSchema),
  })

  const getUrlWithHttpsProtocol = (url: string) => {
    return url ? `https://${url}` : null
  }

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid, errors, isSubmitting },
    register,
    resetField,
    watch,
  } = methods

  const handleChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target
    const field = name as keyof ChecklistFormValues

    setValue(field, value, { shouldValidate: true, shouldDirty: true })

    if (field === 'state_abbr') {
      fetchCountiesByState(value)
      fetchMunicipalitiesByState(value)
      resetField('county_id')
      resetField('municipality_id')
    }
  }, [setValue, resetField])

  const handleClickRequirement = (event: React.MouseEvent<HTMLElement, MouseEvent>, key: keyof ChecklistRequirements) => {
    event.preventDefault()
    event.stopPropagation()
    setRequirements({
      ...requirements,
      [key]: !requirements[key]
    })
  }

  const handlePreviewFile = async (file: File | Attachment) => {
    if ((file as Attachment).id && checklist) {
      const response = await previewChecklistAttachment(checklist.id, (file as Attachment).id)
      const url = URL.createObjectURL(response)
      window.open(url, '_blank')
      URL.revokeObjectURL(url)
    } else {
      const url = URL.createObjectURL(file as File)
      window.open(url, '_blank')
      URL.revokeObjectURL(url)
    }
  }

  const handleDownloadFile = async (file: File | Attachment) => {
    if ((file as Attachment).id && checklist) {
      const attachment = file as Attachment
      const response = await downloadChecklistAttachment(checklist.id, attachment.id)
      const url = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = url
      link.download = attachment.filename
      link.click()
      URL.revokeObjectURL(url)
    } else {
      const asFile = file as File
      const url = URL.createObjectURL(asFile)
      const link = document.createElement('a')
      link.href = url
      link.download = asFile.name
      link.click()
      URL.revokeObjectURL(url)
    }
  }

  const handleSubmitLicenseForm = useCallback(async () => {
    try {
      const formValues = getValues()
      if (checklist) {
        const response = await updateChecklist(checklist.id, {
          state_abbr: formValues.state_abbr!,
          county_id: formValues.county_id || null,
          municipality_id: formValues.municipality_id || null,
          notes: formValues.notes ?? null,
          priority: formValues.priority!,
          requirements: requirements,
          submission_type: formValues.submission_type!,
          submission_email: formValues.submission_type === 'email' && formValues.submission_email ? formValues.submission_email : null,
          submission_url: formValues.submission_type === 'online' && formValues.submission_url ? getUrlWithHttpsProtocol(formValues.submission_url) : null,
          city_contact_name: formValues.city_contact_name ?? null,
          city_contact_email: formValues.city_contact_email ?? null,
          city_contact_phone: formValues.city_contact_phone ?? null,
        })
        if (response._metadata.success && formValues.attachments && formValues.attachments.length >= 0) {
          const currentAttachmentIds = formValues.attachments.map((attachment) => (attachment as Attachment).id).filter((id) => id)
          const deletedAttachmentsIds = checklistAttachments
            .map((attachment) => attachment.id)
            .filter((attachmentId) => !currentAttachmentIds.includes(attachmentId))

          deletedAttachmentsIds.forEach(async (attachmentId) => {
            await deleteChecklistAttachment(checklist.id, attachmentId)
          })

          for (const attachment of formValues.attachments) {
            if (!(attachment as Attachment).id) {
              await uploadChecklistAttachment(checklist.id, attachment as File)
            }
          }
        }
        onSubmit({
          success: response._metadata.success ?? false,
          message: response.result.message
        })
      } else {
        const response = await createChecklist({
          state_abbr: formValues.state_abbr!,
          county_id: formValues.county_id || null,
          municipality_id: formValues.municipality_id || null,
          notes: formValues.notes ?? null,
          priority: formValues.priority!,
          requirements: requirements,
          submission_type: formValues.submission_type!,
          submission_email: formValues.submission_type === 'email' && formValues.submission_email  ? formValues.submission_email : null,
          submission_url: formValues.submission_type === 'online' && formValues.submission_url ? getUrlWithHttpsProtocol(formValues.submission_url) : null,
          city_contact_name: formValues.city_contact_name ?? null,
          city_contact_email: formValues.city_contact_email ?? null,
          city_contact_phone: formValues.city_contact_phone ?? null,
        })
        if (response.result.checklist_id && formValues.attachments && formValues.attachments.length >= 0) {
          for (const attachment of formValues.attachments) {
            await uploadChecklistAttachment(response.result.checklist_id, attachment as File)
          }
        }
        onSubmit({
          success: response._metadata.success ?? false,
          message: response.result.message
        })
      }
    } catch (e) {
      console.error(e)
      onSubmit({
        success: false,
        message: `Error in ${checklist ? 'updating' : 'creating'} checklist.`
      })
    }
  }, [checklist, checklistAttachments, getValues, onSubmit, requirements])

  useEffect(() => {
    if (checklist && checklist.state) {
      fetchCountiesByState(checklist.state.abbreviation)
      fetchMunicipalitiesByState(checklist.state.abbreviation)
    }
  }, [checklist])

  return (
    <div className="w-[576px]">
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(handleSubmitLicenseForm)}>
          <ChecklistFormHeader
            title={checklist ? REQUIREMENTS : NEW_REQUIREMENTS}
            onClose={onCancel}
          />     
          <div className="px-6 grid grid-cols-1 py-6 gap-y-6 gap-x-4 sm:grid-cols-6 h-[473px] overflow-y-auto no-scrollbar">   
            <CustomFormElement
              id={'state_abbr'}
              name={'state_abbr'}
              label={STATE_LABEL}
              type="select"
              selectOptions={[
                ...states.map((state) => ({
                  value: state.abbreviation,
                  name: state.name
                }))
              ]}
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.state_abbr as any}
              formElementWrapperClassName='sm:col-span-2'
              required
              searchable
            />
            <CustomFormElement
              id={'county_id'}
              name={'county_id'}
              label={COUNTY_LABEL}
              type="select"
              selectOptions={[
                ...counties.map((county) => ({
                  value: county.id,
                  name: capitalize(county.name)
                }))
              ]}
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.county_id as any}
              formElementWrapperClassName='sm:col-span-2'
              searchable
              disabled={counties.length === 0}
            />
            <CustomFormElement
              id={'municipality_id'}
              name={'municipality_id'}
              label={MUNICIPALITY_LABEL}
              type="select"
              selectOptions={[
                ...municipalities.map((municipality) => ({
                  value: municipality.id,
                  name: capitalize(municipality.name)
                }))
              ]}
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.municipality_id as any}
              formElementWrapperClassName='sm:col-span-2'
              dropdownPosition='left'
              searchable
              disabled={municipalities.length === 0}
            />
            <CustomFormElement
              id={'city_contact_name'}
              name={'city_contact_name'}
              label={CITY_CONTACT_NAME_LABEL}
              placeholder={CITY_CONTACT_NAME_PLACEHOLDER}
              type="text"
              onChange={handleChange}
              register={register}
              error={errors?.city_contact_name as any}
              formElementWrapperClassName='sm:col-span-6' 
            />
            <CustomFormElement
              id="city_contact_email"
              name="city_contact_email"
              label={CITY_CONTACT_EMAIL_LABEL}
              placeholder={CITY_CONTACT_EMAIL_PLACEHOLDER}
              type="text"
              onChange={handleChange}
              register={register}
              error={errors.city_contact_email as any}
              formElementWrapperClassName='sm:col-span-6' 
            />
            <CustomFormElement<ChecklistFormValues>
              id={'city_contact_phone'}
              name={'city_contact_phone'}
              label={CITY_CONTACT_PHONE_LABEL}
              placeholder={CITY_CONTACT_PHONE_PLACEHOLDER}
              type="phone"
              onChange={handleChange}
              register={register}
              error={errors?.city_contact_phone as any}
              formElementWrapperClassName='sm:col-span-6' 
            />
            <div className='flex flex-col sm:col-span-6 gap-4'>
              <div className='flex justify-between'>
                <div className='text-sm font-medium text-gray-700'>Requirements</div>
                <button
                  className='flex gap-5 mr-3 cursor-pointer'
                  onClick={(e) => {
                    e.preventDefault()
                    setIsViewHistoryOpen(!isViewHistoryOpen)
                  }}
                >
                  <AptiveIcon className="w-5 h-5 stroke-[#4B5563] fill-none" icon={isViewHistoryOpen ? 'chevronUp' : 'chevronDown'} />
                </button>
              </div>
              {isViewHistoryOpen && (
                <div className='grid grid-cols-7'>
                  {Object.keys(requirements).map((key, index) => (   
                    <div key={key} className={index % 2 === 0 ? 'sm:col-span-3' : 'sm:col-span-4'}>
                      <Checkbox
                        checked={requirements[key as keyof ChecklistRequirements]}
                        onClick={(event) => handleClickRequirement(event, key as keyof ChecklistRequirements)}
                      >
                        {ChecklistRequirementsNames[key as keyof ChecklistRequirements]}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <CustomFormElement
              id={'priority'}
              name={'priority'}
              label={PRIORITY_LABEL}
              type="select"
              selectOptions={[...prioritySelectionOption ]}
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.priority as any}
              formElementWrapperClassName='sm:col-span-6'
              required
            />
            <CustomFormElement
              id={'submission_type'}
              name={'submission_type'}
              label={SUBMISSION_TYPE_LABEL}
              type="select"
              selectOptions={[ ...submissionTypeSelectionOption ]}
              onChange={handleChange}
              register={register}
              watch={watch}
              error={errors?.submission_type as any}
              formElementWrapperClassName='sm:col-span-6'
              required
            />
            {getValues().submission_type === 'email' && (
              <CustomFormElement
                id={'submission_email'}
                name={'submission_email'}
                label={SUBMISSION_EMAIL_ADDRESS_LABEL}
                placeholder={SUBMISSION_EMAIL_ADDRESS_PLACEHOLDER}
                type="text"
                onChange={handleChange}
                register={register}
                error={errors?.submission_email as any}
                formElementWrapperClassName='sm:col-span-6'
                required
              />
            )}
            {getValues().submission_type === 'online' && (
              <CustomFormElement
                id={'submission_url'}
                name={'submission_url'}
                label={SUBMISSION_URL_LABEL}
                placeholder={SUBMISSION_URL_PLACEHOLDER}
                type="text"
                onChange={handleChange}
                register={register}
                error={errors?.submission_url as any}
                formElementWrapperClassName='sm:col-span-6'
                className='pl-14 pr-4 py-2'
                mark={SUBMISSION_URL_MARK}
                required
              />
            )}
            <CustomFormElement
              id={'notes'}
              name={'notes'}
              placeholder={NOTES_TYPE_PLACEHOLDER}
              label={NOTES_TYPE_LABEL}
              type="textArea"
              rows={1}
              onChange={handleChange}
              register={register}
              error={errors?.notes as any}
              formElementWrapperClassName='sm:col-span-6'
            />
            <div className="sm:col-span-6 flex flex-col gap-4">
              <CustomFormElement
                id={'attachments'}
                name={'attachments'}
                type="file"
                attachmentLabel={ATTACHMENTS_LABEL}
                onChange={handleChange}
                defaultFiles={getValues('attachments')}
                acceptedFileTypes={['application/pdf']}
                maxFileSize={30 * 1024 * 1024}
                maxFileCount={1}
                register={register}
                error={errors?.attachments as any}
                onPreviewFile={handlePreviewFile}
                onDownloadFile={handleDownloadFile}
              />
            </div>
          </div>
          <ChecklistFormFooter
            onCancel={onCancel}
            onSubmit={() => {}}
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </form>
      </FormProvider>
    </div>
  )
}

export default ChecklistForm
