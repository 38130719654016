import { License, SalesRepresentative, Summary } from 'interface'
import { apiClient } from './config'

export const fetchProfile = async () : Promise<{
  _metadata: {
    success: boolean
  },
  result: {
    licenses: License[],
    rep: SalesRepresentative,
    summary: Summary
  }
}> => {
  const response = await apiClient('auth/me', {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return response
}
